<template>
	<div id="app" class="zutool-wrapper sugotoku">
		<router-view />
	</div>
</template>

<script>
// Vuex
import { mapActions } from 'vuex'
import { SET_IS_ANDROID } from './store/modules/common/mutation-types'

export default {
	name: 'App',
	components: {
	},
	data () {
		return {
		}
	},
	computed: {
	},
	created () {
		// NOTE: 横画面時のアラート処理
		const ons = this.$ons
		ons.orientation.on('change', (e) => {
			if (!e.isPortrait) {
				ons.notification.alert({ message: '画面を縦にしてご利用ください。', title: '' })
			}
		})

		// Android判定しstoreに格納
		// NOTE: User-Agent Client Hints対応
		const userAgentCh = window.navigator.userAgentData
		// console.log('userAgentCh: ', userAgentCh)	// Debug用
		// User-Agent Client Hintsが利用できるようであれば
		if (userAgentCh) {
			this.SET_IS_ANDROID(userAgentCh.platform.toLowerCase() === 'android')
		} else {
			const userAgent = window.navigator.userAgent.toLowerCase()
			this.SET_IS_ANDROID(userAgent.indexOf('android') >= 0)
		}
	},
	mounted () {
		// NOTE: スゴ得用のinputタグを削除
		const domState = document.getElementById('js_state')
		const domFav = document.getElementById('js_fav')
		if (domState) domState.parentNode.removeChild(domState)
		if (domFav) domFav.parentNode.removeChild(domFav)
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_IS_ANDROID])
	}
}
</script>

<style lang="scss">
@import '~onsenui/css/onsenui.css';
// TODO: テーマローラーで作成したものに差し替える
@import '~onsenui/css/onsen-css-components.css';
// @import "assets/sass/overview";

// 変数定義SASS
@import "assets/sass/variable";

// 共通SASS
@import "assets/sass/common";

// スゴ得用レイアウト
.zutool-wrapper.sugotoku {
	position: relative;
	// NOTE: デフォルトは100%でコンテンツ領域確保。スゴ得の場合、各ページコンポーネントで実コンテンツ領域を確保する。
	// TODO: 100vhなら実コンテンツ領域を確保する必要ないのでは？手が空いたときに試してみる。
	height: 100%;
	margin-bottom: $spacing-40;

	// NOTE: スゴ得フッターのmargin-topを打ち消すclass
	// CHANGE: ドコモフッター変更により処理が不要になったのでコメントアウト
	// &.is-negative-margin {
	// 	margin-bottom: -40px;
	// }
}
</style>
