import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		weatherForecastData: null,
		healthWeatherData: null,
		solarTimeData: null,
		typhoonListData: null,
		typhoonInformationData: null
	},

	mutations: {
		[types.SET_WEATHER_FORECAST] (state, weatherForecastData) {
			state.weatherForecastData = weatherForecastData
		},
		[types.SET_HEALTH_WEATHER] (state, healthWeatherData) {
			state.healthWeatherData = healthWeatherData
		},
		[types.SET_SOLAR_TIME] (state, solarTimeData) {
			state.solarTimeData = solarTimeData
		},
		[types.SET_TYPHOON_LIST] (state, typhoonListData) {
			state.typhoonListData = typhoonListData
		},
		[types.SET_TYPHOON_INFORMATION] (state, typhoonInformationData) {
			state.typhoonInformationData = typhoonInformationData
		}
	},

	actions: {
		// 天気予報（日別）データを取得するAPI
		[types.SET_WEATHER_FORECAST] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getweatherIndex.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to
					}
				}).then(res => {
					// NOTE: result_codeが1001以外はエラー
					if (res.data.result_code !== 1001) {
						commit(types.SET_WEATHER_FORECAST, null)
						reject(res)
					} else {
						commit(types.SET_WEATHER_FORECAST, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_WEATHER_FORECAST, null)
					reject(error)
				})
			})
		},

		// 健康天気指数データを取得するAPI
		// NOTE: SET_WEATHER_FORECASTと同じAPIだが、別のstateデータを扱いたいため別途用意した。
		[types.SET_HEALTH_WEATHER] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getweatherIndex.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to
					}
				}).then(res => {
					// NOTE: result_codeが1001以外はエラー
					if (res.data.result_code !== 1001) {
						commit(types.SET_HEALTH_WEATHER, null)
						reject(res)
					} else {
						commit(types.SET_HEALTH_WEATHER, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_HEALTH_WEATHER, null)
					reject(error)
				})
			})
		},

		// 日出没時刻を取得するAPI
		[types.SET_SOLAR_TIME] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getSolarTime.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						year: payload.year,
						month: payload.month,
						day: payload.day,
						city_code: payload.city_code
					}
				}).then(res => {
					// NOTE: このAPIは異常のときだけresult_codeがある
					if (res.data.result_code && res.data.result_code !== 1001) {
						commit(types.SET_SOLAR_TIME, null)
						reject(res)
					} else {
						commit(types.SET_SOLAR_TIME, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_SOLAR_TIME, null)
					reject(error)
				})
			})
		},

		// 台風情報一覧を取得するAPI
		[types.SET_TYPHOON_LIST] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getTyphoonAlive.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token
					}
				}).then(res => {
					// NOTE: result_codeが1001以外はエラー
					if (res.data.result_code !== 1001) {
						commit(types.SET_TYPHOON_LIST, null)
						reject(res)
					} else {
						commit(types.SET_TYPHOON_LIST, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_TYPHOON_LIST, null)
					reject(error)
				})
			})
		},

		// 台風情報詳細を取得するAPI
		[types.SET_TYPHOON_INFORMATION] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getTyphoonInfo.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						typhoon_number: payload.typhoon_number
					}
				}).then(res => {
					// NOTE: result_codeが1001以外はエラー
					if (res.data.result_code !== 1001) {
						commit(types.SET_TYPHOON_INFORMATION, null)
						reject(res)
					} else {
						commit(types.SET_TYPHOON_INFORMATION, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_TYPHOON_INFORMATION, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		weatherForecastData (state) {
			return state.weatherForecastData
		},
		healthWeatherData (state) {
			return state.healthWeatherData
		},
		solarTimeData (state) {
			return state.solarTimeData
		},
		typhoonListData (state) {
			return state.typhoonListData
		},
		typhoonInformationData (state) {
			return state.typhoonInformationData
		}
	}
}
