import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'	// NOTE: スマパス終了とWebviewとスゴ得のソースを分ける対応で現状不要となったが、今後スゴ得仕様変更などでServiceWorkerを使用する必要が出た際に再利用するためコメントアウトで取っておく。
import VueOnsen from 'vue-onsenui'
import VueScrollTo from 'vue-scrollto'

// NOTE: createjsを読み込む
const createjs = require('createjs')

Vue.use(VueOnsen)
Vue.use(VueScrollTo)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	createjs,
	render: h => h(App)
}).$mount('#app')
