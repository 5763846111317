import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.WP_REST_API_URL}?rest_route=/wp/v2`

export default {
	namespaced: true,

	state: {
		columnListData: null,
		columnDetailData: null,
		columnCategoryData: null
	},

	mutations: {
		[types.SET_COLUMN_LIST] (state, columnListData) {
			state.columnListData = columnListData
		},
		[types.SET_COLUMN_LIST_GENERIC] (state, columnListData) {
			state.columnListData = columnListData
		},
		[types.SET_COLUMN_DETAIL] (state, columnDetailData) {
			state.columnDetailData = columnDetailData
		},
		[types.SET_COLUMN_CATEGORY] (state, columnCategoryData) {
			state.columnCategoryData = columnCategoryData
		}
	},

	actions: {
		// コラム一覧の取得
		[types.SET_COLUMN_LIST] ({ commit }, payload) {
			const queryString = `&_embed&orderby=modified&order=desc&per_page=${payload.per_page}&page=${payload.page}&categories=${payload.categories}`
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}/posts${queryString}`,
					params: {
						per_page: payload.per_page,
						page: payload.page,
						categories: payload.categories
					}
				}).then(res => {
					const columnListData = res.data
					commit(types.SET_COLUMN_LIST, columnListData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_COLUMN_LIST, null)
					reject(error)
				})
			})
		},

		// WP REST APIに準拠したリクエストができる汎用的なコラム一覧の取得用
		[types.SET_COLUMN_LIST_GENERIC] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}/posts${payload.query_string}`
				}).then(res => {
					const columnListData = res.data
					commit(types.SET_COLUMN_LIST, columnListData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_COLUMN_LIST, null)
					reject(error)
				})
			})
		},

		// コラム詳細の取得
		[types.SET_COLUMN_DETAIL] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}/posts/${payload.detail_id}`,
					params: {
						detail_id: payload.detail_id
					}
				}).then(res => {
					const columnDetailData = res.data
					commit(types.SET_COLUMN_DETAIL, columnDetailData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_COLUMN_DETAIL, null)
					reject(error)
				})
			})
		},

		// カテゴリ一覧の取得
		[types.SET_COLUMN_CATEGORY] ({ commit }) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}/categories/`
				}).then(res => {
					const columnCategoryData = res.data
					commit(types.SET_COLUMN_CATEGORY, columnCategoryData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_COLUMN_CATEGORY, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		columnListData (state) {
			return state.columnListData
		},
		columnDetailData (state) {
			return state.columnDetailData
		},
		columnCategoryData (state) {
			return state.columnCategoryData
		}
	}
}
