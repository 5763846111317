import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.API_URL}/api/`
const uriV2 = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		menuOpen: false,
		cityName: '',
		userId: null,
		userToken: null,
		userData: null,
		profileTutorialFlag: false,
		graphDate: null,
		menuItem: null,
		isMyPage: false,
		viewMode: null,
		isAndroid: false,
		isWebview: false,
		isDownloadPopUp: true,	// NOTE: スゴ得TOPのポップアップを今日表示したかのフラグ
		premiumType: null,
		visiblePopUpType: '',	// NOTE: スゴ得痛み記録ボタン・プロフィールをクリックしたときのポップアップ表示判定用の変数（文字列）
		s3CityCodeData: null
	},

	mutations: {
		[types.SET_MENU_OPEN] (state, menuOpen) {
			state.menuOpen = menuOpen
		},
		[types.SET_CITY_NAME] (state, cityName) {
			state.cityName = cityName
		},
		[types.SET_USER_ID] (state, userId) {
			state.userId = userId
		},
		[types.SET_USER_TOKEN] (state, userToken) {
			state.userToken = userToken
		},
		[types.SET_USER_DATA] (state, userData) {
			state.userData = userData
		},
		[types.SET_PROFILE_TURORIAL_FLAG] (state, profileTutorialFlag) {
			state.profileTutorialFlag = profileTutorialFlag
		},
		[types.SET_GRAPH_DATE] (state, graphDate) {
			state.graphDate = graphDate
		},
		[types.SET_MENU_ITEM] (state, menuItem) {
			state.menuItem = menuItem
		},
		[types.SET_IS_MY_PAGE] (state, isMyPage) {
			state.isMyPage = isMyPage
		},
		[types.SET_VIEW_MODE] (state, viewMode) {
			state.viewMode = viewMode
		},
		[types.SET_IS_ANDROID] (state, isAndroid) {
			state.isAndroid = isAndroid
		},
		[types.SET_IS_WEBVIEW] (state, isWebview) {
			state.isWebview = isWebview
		},
		[types.SET_IS_DOWNLOAD_POPUP] (state, isDownloadPopUp) {
			state.isDownloadPopUp = isDownloadPopUp
		},
		[types.SET_PREMIUM_TYPE] (state, premiumType) {
			state.premiumType = premiumType
		},
		[types.SET_VISIBLE_POPUP_TYPE] (state, visiblePopUpType) {
			state.visiblePopUpType = visiblePopUpType
		},
		[types.SET_S3_CITY_CODE_DATA] (state, s3CityCodeData) {
			state.s3CityCodeData = s3CityCodeData
		}
	},

	actions: {
		[types.SET_MENU_OPEN] ({ commit }, menuOpen) {
			commit(types.SET_MENU_OPEN, menuOpen)
		},
		[types.SET_CITY_NAME] ({ commit }, cityName) {
			commit(types.SET_CITY_NAME, cityName)
		},
		[types.SET_USER_ID] ({ commit }, userId) {
			commit(types.SET_USER_ID, userId)
		},
		[types.SET_USER_TOKEN] ({ commit }, userToken) {
			commit(types.SET_USER_TOKEN, userToken)
		},
		// NOTE: ユーザ情報取得API
		[types.SET_USER_DATA] ({ commit }, payload) {
			const params = payload.is_launch ? {
				user_id: payload.user_id,
				user_token: payload.user_token,
				is_launch: payload.is_launch
			} : {
				user_id: payload.user_id,
				user_token: payload.user_token
			}
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getuser.json`,
					// NOTE: 仕様書に定額用のリクエストヘッダーの記載があるが、バックエンドの処理のようでフロントでは不要（フロント側でheaderを入れるとCORSエラーになる）。
					// headers: { 'X-Zutool-Teigaku-Code': 1 },	// NOTE: 定額サービス用の定額コード
					params: params
				}).then(res => {
					const userData = res.data.user
					commit(types.SET_USER_DATA, userData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_USER_DATA, null)
					reject(error)
				})
			})
		},
		// NOTE: ユーザ情報の地点コード登録API
		[types.SET_USER_CITY_CODE] ({ commit, state }, payload) {
			const cityCode = payload.city_code
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}registpoint.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						city_code: cityCode
					}
				}).then(res => {
					// NOTE: storeのuserData.city_codeを更新してコミットする
					const userData = state.userData
					userData.city_code = cityCode
					commit(types.SET_USER_DATA, userData)
					resolve(res)
				}).catch(error => {
					// NOTE: エラー時はuserDataを更新しないでリジェクトする
					reject(error)
				})
			})
		},
		// NOTE: methodがgetなのが気になる
		updateUserData ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}updateuser.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						birthday: payload.birthday,
						sex: payload.sex,
						disease: payload.disease
					}
				}).then(res => {
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// NOTE: 痛み記録用チュートリアル表示用フラグ
		[types.SET_PROFILE_TURORIAL_FLAG] ({ commit }, profileTutorialFlag) {
			commit(types.SET_PROFILE_TURORIAL_FLAG, profileTutorialFlag)
		},
		// NOTE: プロフィール登録後に戻ってきた時にクリックしていた記録の時間などを保持する
		[types.SET_GRAPH_DATE] ({ commit }, graphDate) {
			commit(types.SET_GRAPH_DATE, graphDate)
		},
		// NOTE: メニュー広告取得
		[types.SET_MENU_ITEM] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}banner.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token
					}
				}).then(res => {
					const menuItem = res.data
					commit(types.SET_MENU_ITEM, menuItem)
					resolve(res)
				}).catch(error => {
					commit(types.SET_MENU_ITEM, null)
					reject(error)
				})
			})
		},
		[types.SET_IS_MY_PAGE] ({ commit }, isMyPage) {
			commit(types.SET_IS_MY_PAGE, isMyPage)
		},
		[types.SET_VIEW_MODE] ({ commit }, viewMode) {
			commit(types.SET_VIEW_MODE, viewMode)
		},
		[types.SET_IS_ANDROID] ({ commit }, isAndroid) {
			commit(types.SET_IS_ANDROID, isAndroid)
		},
		[types.SET_IS_WEBVIEW] ({ commit }, isWebview) {
			commit(types.SET_IS_WEBVIEW, isWebview)
		},
		updateWebStorage ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'post',
					url: `${uriV2}updatewebstorage.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						data: payload.data
					}
				}).then(res => {
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		[types.SET_IS_DOWNLOAD_POPUP] ({ commit }, isDownloadPopUp) {
			commit(types.SET_IS_DOWNLOAD_POPUP, isDownloadPopUp)
		},
		// NOTE: 課金タイプ取得
		// 0: 判別できず
		// 1: 無課金状態
		// 2: お試し期間
		// 3: 年間プラン
		// 4: 月間プラン
		// 5: キャンペーンユーザー
		// 6: AppPass
		// 7: スマパス
		// 8: スゴ得
		[types.SET_PREMIUM_TYPE] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}getpremiumtype.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token
					}
				}).then(res => {
					// NOTE: エラーレスポンス外でもエラーになる場合があるので、statusがNGの場合はrejectするようにした。
					if (res.data.head && res.data.head.status === 'NG') {
						commit(types.SET_PREMIUM_TYPE, null)
						reject(res)
					// NOTE: ユーザートークンエラー
					} else if (res.data.result_code === 4001) {
						commit(types.SET_PREMIUM_TYPE, null)
						reject(res)
					} else {
						commit(types.SET_PREMIUM_TYPE, res.data)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_PREMIUM_TYPE, null)
					reject(error)
				})
			})
		},
		[types.SET_VISIBLE_POPUP_TYPE] ({ commit }, visiblePopUpType) {
			commit(types.SET_VISIBLE_POPUP_TYPE, visiblePopUpType)
		},
		// NOTE: S3の地点データ取得
		[types.SET_S3_CITY_CODE_DATA] ({ commit }) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${cmnConst.S3_RESOURCE_URL}/citycode.json`
				}).then(res => {
					const s3CityCodeData = res.data
					commit(types.SET_S3_CITY_CODE_DATA, s3CityCodeData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_S3_CITY_CODE_DATA, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		menuOpen (state) {
			return state.menuOpen
		},
		cityName (state) {
			return state.cityName
		},
		userId (state) {
			return state.userId
		},
		userToken (state) {
			return state.userToken
		},
		userData (state) {
			return state.userData
		},
		profileTutorialFlag (state) {
			return state.profileTutorialFlag
		},
		graphDate (state) {
			return state.graphDate
		},
		menuItem (state) {
			return state.menuItem
		},
		isMyPage (state) {
			return state.isMyPage
		},
		viewMode (state) {
			return state.viewMode
		},
		isAndroid (state) {
			return state.isAndroid
		},
		isWebview (state) {
			return state.isWebview
		},
		isDownloadPopUp (state) {
			return state.isDownloadPopUp
		},
		premiumType (state) {
			return state.premiumType
		},
		visiblePopUpType (state) {
			return state.visiblePopUpType
		},
		s3CityCodeData (state) {
			return state.s3CityCodeData
		}
	}
}
