import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

// API(v1)のURL
const uriV1 = `${cmnConst.API_URL}/api/`
// API(v2)のURL
const uriV2 = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		weatherData: null,
		recordeData: null,
		recordeDataWholePeriod: null,
		talkData: null
	},

	mutations: {
		[types.SET_WEATHER_DATA] (state, weatherData) {
			state.weatherData = weatherData
		},
		[types.SET_RECORD_DATA] (state, recordeData) {
			state.recordeData = recordeData
		},
		[types.SET_RECORD_DATA_WHOLEPERIOD] (state, recordeDataWholePeriod) {
			state.recordeDataWholePeriod = recordeDataWholePeriod
		},
		[types.SET_TALK_DATA] (state, talkData) {
			state.talkData = talkData
		}
	},

	actions: {
		// NOTE: 気象データ取得API
		[types.SET_WEATHER_DATA] ({ commit }, payload) {
			const includeRiseWarning = payload.include_rise_warning ? payload.include_rise_warning : 0
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}getweathers.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to,
						include_rise_warning: includeRiseWarning
					}
				}).then(res => {
					const weatherData = res.data
					commit(types.SET_WEATHER_DATA, weatherData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_WEATHER_DATA, null)
					reject(error)
				})
			})
		},
		// NOTE: 記録データ登録API NOTE: V2のAPIには今回使わないパラメータがついていたのでV1を使用
		updateRecordData ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV1}recordpain.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						pain: payload.pain,
						drug: payload.drug,
						comment: payload.comment,
						hour: payload.hour
					}
				}).then(res => {
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// NOTE: 記録データ削除API
		deleteRecordData ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV1}deletepain.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						hour: payload.hour
					}
				}).then(res => {
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// NOTE: 記録データの取得
		[types.SET_RECORD_DATA] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}getpaininperiod.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to
					}
				}).then(res => {
					const recordeData = res.data
					commit(types.SET_RECORD_DATA, recordeData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_RECORD_DATA, null)
					reject(error)
				})
			})
		},
		// 記録データの取得（登録日から全期間）
		[types.SET_RECORD_DATA_WHOLEPERIOD] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}getpaininperiod.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to
					}
				}).then(res => {
					const recordeDataWholePeriod = res.data
					commit(types.SET_RECORD_DATA_WHOLEPERIOD, recordeDataWholePeriod)
					resolve(res)
				}).catch(error => {
					commit(types.SET_RECORD_DATA_WHOLEPERIOD, null)
					reject(error)
				})
			})
		},
		// おしゃべりコーナーのデータを取得する
		[types.SET_TALK_DATA] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uriV2}gettalkdata.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token
					}
				}).then(res => {
					// NOTE: ユーザートークンエラー
					if (res.data.result_code === 4001) {
						commit(types.SET_TALK_DATA, null)
						reject(res)
					} else {
						const talkData = res.data
						commit(types.SET_TALK_DATA, talkData)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_TALK_DATA, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		weatherData (state) {
			return state.weatherData
		},
		recordeData (state) {
			return state.recordeData
		},
		recordeDataWholePeriod (state) {
			return state.recordeDataWholePeriod
		},
		talkData (state) {
			return state.talkData
		}
	}
}
