import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		menstruationData: null
	},

	mutations: {
		[types.SET_MENSTRUATION_DATA] (state, menstruationData) {
			state.menstruationData = menstruationData
		}
	},

	actions: {
		// 生理記録データ取得API（期間）
		[types.SET_MENSTRUATION_DATA] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getmenstruationperiod.json`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to
					}
				}).then(res => {
					// NOTE: エラーレスポンス外でもエラーになる場合があるので、statusがNGの場合はrejectするようにした。
					if (res.data.head && res.data.head.status === 'NG') {
						commit(types.SET_MENSTRUATION_DATA, null)
						reject(res)
					// NOTE: エラー
					} else if (res.data.result_code === 4001) {
						commit(types.SET_MENSTRUATION_DATA, null)
						reject(res)
					} else {
						const menstruationData = res.data
						commit(types.SET_MENSTRUATION_DATA, menstruationData)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_MENSTRUATION_DATA, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		menstruationData (state) {
			return state.menstruationData
		}
	}
}
