import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
import cmnConst from '@/assets/js/constant.js'

// スゴ得ユーザーID・ユーザートークン取得用セパレータ
const SEPARATOR = cmnConst.SEPARATOR

// スゴ得サブディレクトリの定数
const SUGOTOKU_DIR = process.env.VUE_APP_PLATFORM === 'stage_sugotoku' || process.env.VUE_APP_PLATFORM === 'prod_sugotoku' ? '/cs' : ''

// NOTE: ローカル開発用ユーザーID・ユーザートークン（au iPhone5）
const LOCAL_USER_ID = '5ee36e8914566fce578b4567'
const LOCAL_USER_TOKEN = 'bgwhYveWcD5CLfXFSJ6wpDVbN9z2U966'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.VUE_APP_PLATFORM === 'stage_sugotoku' || process.env.VUE_APP_PLATFORM === 'prod_sugotoku' ? './' : process.env.BASE_URL,
	routes: [
		{
			path: '/error',
			name: 'Error',
			component: () => import(/* webpackChunkName: "SorryPage" */ '@/views/SorryPage'),
			props: {
				text: 'サーバーで問題が発生しているため、ページを表示できません。<br>しばらく時間を置いてからやり直してください。'
			}
		},
		// ソーリーページ（非対応端末エラー）
		{
			path: '/sorryPage',
			name: 'SorryPage',
			component: () => import(/* webpackChunkName: "SorryPage" */ '@/views/SorryPage'),
			props: {
				text: '申し訳ございません。<br>お使いの端末ではこのサービスをご利用いただけません。'
			}
		},
		// スゴ得用ページ
		{
			path: `${SUGOTOKU_DIR}/cpsite.html`,
			name: 'Cpsite',
			component: () => import(/* webpackChunkName: "Cpsite" */ '@/views/Cpsite'),
			meta: { requiresAuth: true }
		},
		// 404 NotFound
		{
			path: `/*`,
			name: 'NotFound',
			component: () => import(/* webpackChunkName: "SorryPage" */ '@/views/SorryPage'),
			props: {
				text: '申し訳ございません。<br>お探しのページは見つかりません。'
			}
		}
		// TODO: その他開発時に追記
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	stringifyQuery: query => {
		// スゴ得URL対応のため、エスケープしたクエリではなく文字列としてクエリを扱う
		const keys = Object.keys(query)
		let strQuery = keys.length === 0 ? '' : '?' + keys.map(key => `${key}=${query[key]}`).join('&')
		// CHANGE: iOS13.4以下でreplaceAllが使用できないため修正
		// strQuery = strQuery.replaceAll('/', '//')	// スラッシュがエスケープされてしまうので置換
		strQuery = strQuery.replace(/\//g, '//')	// スラッシュがエスケープされてしまうので置換
		return strQuery
	}
})

// スゴ得認証用のオブジェクト
const sugotokuAuth = {
	loggedIn: false,
	login () { this.loggedIn = true },
	logout () { this.loggedIn = false }
}

// ナビゲーションガード
router.beforeEach((to, from, next) => {
	// TODO: スゴ得専用にしたため、SET_VIEW_MODEは不要かも。Webviewと定額ソースを分ける作業の最後に確認し不要であれば消す。
	if (!store.getters['common/veiwMode']) store.dispatch('common/SET_VIEW_MODE', 'sugotoku')

	// userId/userTokenのチェック
	// console.log(process.env.NODE_ENV)
	if (!store.getters['common/isWebview'] && (!store.getters['common/userId'] || !store.getters['common/userToken'])) {
		const state = document.getElementById('js_state').value
		const stateArray = state.split(SEPARATOR)

		if (stateArray.length === 2) {
			const userId = stateArray[0]
			const userToken = stateArray[1]
			const isMyPage = Number(document.getElementById('js_fav').value) === 1
			store.dispatch('common/SET_USER_ID', userId)
			store.dispatch('common/SET_USER_TOKEN', userToken)
			store.dispatch('common/SET_IS_MY_PAGE', isMyPage)
			sugotokuAuth.login()
		} else if (to.query.url && to.query.url.indexOf('webview') > -1) {
			store.dispatch('common/SET_IS_WEBVIEW', true)
			sugotokuAuth.login()
		} else if (process.env.VUE_APP_PLATFORM === 'dev_sugotoku') {
			store.dispatch('common/SET_USER_ID', LOCAL_USER_ID)
			store.dispatch('common/SET_USER_TOKEN', LOCAL_USER_TOKEN)
			store.dispatch('common/SET_IS_MY_PAGE', false)
			sugotokuAuth.login()
		} else {
			sugotokuAuth.logout()
		}
	}

	// console.log('to: ', to)	// debug用
	const query = to.query

	// 非会員のケース
	if (to.matched.some(record => record.meta.requiresAuth) && !sugotokuAuth.loggedIn && to.fullPath.indexOf('nonMember') < 0) {
		// CHANGED: 基本URLパラメータ（id、url、_path）以外を引き継ぐようにした
		delete query.id
		delete query.url
		delete query._path
		next({
			path: `${SUGOTOKU_DIR}/cpsite.html?url=${cmnConst.BACKEND_URL}?_path=nonMember`,
			query: query
		})
		return false	// CHANGED: nextのあとも処理が実行されているようなので、return falseするようにした
	} else if (sugotokuAuth.loggedIn && to.fullPath.indexOf('nonMember') > -1) {	// 会員なのに非会員ページに遷移したケース
		// CHANGED: 会員なのに非会員ページに遷移した場合、TOPページに遷移するようにした。また、基本URLパラメータ（url、_path）以外を引き継ぐようにした
		delete query.url
		delete query._path
		next({
			path: `${SUGOTOKU_DIR}/cpsite.html?id=${cmnConst.SUGOTOKU_CONTENTS_ID}`,
			query: query
		})
		return false	// CHANGED: nextのあとも処理が実行されているようなので、return falseするようにした
	}
	next()
})

export default router
