export const SET_MENU_OPEN = 'SET_MENU_OPEN'
export const SET_CITY_NAME = 'SET_CITY_NAME'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_USER_TOKEN = 'SET_USER_TOKEN'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_CITY_CODE = 'SET_USER_CITY_CODE'
export const SET_PROFILE_TURORIAL_FLAG = 'SET_PROFILE_TURORIAL_FLAG'
export const SET_GRAPH_DATE = 'SET_GRAPH_DATE'
export const SET_MENU_ITEM = 'SET_MENU_ITEM'
export const SET_IS_MY_PAGE = 'SET_IS_MY_PAGE'
export const SET_VIEW_MODE = 'SET_VIEW_MODE'
export const SET_IS_ANDROID = 'SET_IS_ANDROID'
export const SET_IS_WEBVIEW = 'SET_IS_WEBVIEW'
export const SET_IS_DOWNLOAD_POPUP = 'SET_IS_DOWNLOAD_POPUP'	// NOTE: スゴ得TOPのポップアップを今日表示したかのフラグに関するミューテーション
export const SET_PREMIUM_TYPE = 'SET_PREMIUM_TYPE'
export const SET_VISIBLE_POPUP_TYPE = 'SET_VISIBLE_POPUP_TYPE'	// NOTE: スゴ得痛み記録ボタン・プロフィールをクリックしたときのポップアップ表示文字列に関するミューテーション
export const SET_S3_CITY_CODE_DATA = 'SET_S3_CITY_CODE_DATA'	// NOTE: S3の地点データに関するミューテーション
