import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		painData: null,
		painInperiodData: null,
		reportData: null
	},

	mutations: {
		[types.SET_PAIN_DATA] (state, painData) {
			state.painData = painData
		},
		[types.SET_PAININPERIOD_DATA] (state, painInperiodData) {
			state.painInperiodData = painInperiodData
		},
		[types.SET_REPORT_DATA] (state, reportData) {
			state.reportData = reportData
		}
	},

	actions: {
		// 記録データ取得API（件数）
		[types.SET_PAIN_DATA] ({ commit }, payload) {
			const includeRiseWarning = payload.include_rise_warning ? payload.include_rise_warning : 0
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getpain`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						offset: payload.offset,
						limit: payload.limit,
						include_rise_warning: includeRiseWarning
					}
				}).then(res => {
					// NOTE: エラーレスポンス外でもエラーになる場合があるので、statusがNGの場合はrejectするようにした。
					if (res.data.head && res.data.head.status === 'NG') {
						commit(types.SET_PAIN_DATA, null)
						reject(res)
					// NOTE: ユーザートークンエラー
					} else if (res.data.result_code === 4001) {
						commit(types.SET_PAIN_DATA, null)
						reject(res)
					} else {
						const painData = res.data
						commit(types.SET_PAIN_DATA, painData)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_PAIN_DATA, null)
					reject(error)
				})
			})
		},
		// 記録データ取得API（期間）
		[types.SET_PAININPERIOD_DATA] ({ commit }, payload) {
			const includeRiseWaring = payload.include_rise_warning ? payload.include_rise_warning : 0
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getpaininperiod`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						from: payload.from,
						to: payload.to,
						include_rise_warning: includeRiseWaring
					}
				}).then(res => {
					// NOTE: エラーレスポンス外でもエラーになる場合があるので、statusがNGの場合はrejectするようにした。
					if (res.data.head && res.data.head.status === 'NG') {
						commit(types.SET_PAININPERIOD_DATA, null)
						reject(res)
					// NOTE: ユーザートークンエラー
					} else if (res.data.result_code === 4001) {
						commit(types.SET_PAININPERIOD_DATA, null)
						reject(res)
					} else {
						const painInperiodData = res.data
						commit(types.SET_PAININPERIOD_DATA, painInperiodData)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_PAININPERIOD_DATA, null)
					reject(error)
				})
			})
		},
		// 月毎レポート用データ取得
		[types.SET_REPORT_DATA] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getreportmonth`,
					params: {
						user_id: payload.user_id,
						user_token: payload.user_token,
						month: payload.month, // unixtime
						format: 'json'
					}
				}).then(res => {
					const reportData = res.data
					commit(types.SET_REPORT_DATA, reportData)
					resolve(res)
				}).catch(error => {
					commit(types.SET_REPORT_DATA, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		painData (state) {
			return state.painData
		},
		painInperiodData (state) {
			return state.painInperiodData
		},
		reportData (state) {
			return state.reportData
		}
	}
}
