const isStage = process.env.VUE_APP_PLATFORM === 'stage_sugotoku' ||
process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ||
process.env.NODE_ENV === 'test'

export default Object.freeze({
	// 各要素のサイズ指定
	HEADER_HEIGHT: 44,
	FOOTER_HEIGHT: 50,
	INIT_STAGE_WIDTH: 640,
	INIT_STAGE_HEIGHT: 1136,
	// 単純な乗除計算をする際の定数（各要素のサイズや個数のための用途とは異なる）
	TWO_TIMES: 2,
	// タイポグラフィ
	FAMILY_PRIMARY: `'M+ C Type-1 (basic latin) Medium'`,
	FAMILY_SECONDARY: `'M+ C Type-1 (basic latin) Light'`,
	// カラーパレット
	ZUTOOLGREEN: '#1EBFBB',
	BLACKGREY: '#4C4C4C',
	GREY: '#CCC',
	SILVERGREY: '#E6E6E6',
	DARKGREY: '#666',
	WHITE: '#FFFFFF',
	BLACK: '#000000',
	LIGHTGREY: '#F0F0F0',
	ORANGE: '#FF4E00',
	SKYBLUE: '#55B1FF',
	ROYALBLUE: '#0042FF',
	GOLD: '#F6B552',
	PINK: '#F37E82',
	CRIMSON: '#E23B49',
	BLUEVIOLET: '#7E20FD',
	MINT: '#B0FFE2',
	SANDYBROWN: '#FF9F5C',
	SALMON: '#FF4D4D',
	VIOLET: '#CC00CC',
	LIGHTBLUE: '#B3FAFF',
	LIGHTGREEN: '#D3EDB0',
	LIGHTGOLD: '#FFD486',
	LIGHTPINK: '#F7A6AC',
	COLAL: '#F95959',
	DARKGREYBLUE: '#2A5680',
	STEELBLUE: '#3B788A',
	LIGHTSKYBLUE: '#59C2FF',
	POWDERBLUE: '#96E7FF',
	DIMGRAY: '#999999',
	BLUE: '#0071DB',
	CORNFLOWERBLUE: '#6EA5DB',
	BLUEGREY: '#B0C6DB',
	// 初期表示チュートリアル
	GRAPH_TUTORIAL_KEY: 'isPointRegistrationComplete',
	GRAPH_TUTORIAL_IMG: 'static/tutorial/weather_graph@2x.png',
	BACKEND_URL: isStage ? 'https://stage-sugotoku-zutool.nextinfra.info/sgtk' : 'https://sugotoku-zutool.pocke.tv/sgtk',
	FRONT_URL: process.env.VUE_APP_PLATFORM === 'stage_sugotoku' ? 'https://stage-sugotoku.zutool.jp/'
		: process.env.VUE_APP_PLATFORM === 'prod_sugotoku' ? 'https://sugotoku.zutool.jp/'
			: '',
	API_URL: isStage ? 'https://stage-zutsu.nextinfra.info' : 'https://zutool.pocke.tv',
	WP_REST_API_URL: isStage ? 'https://stage-sugotoku-zutool.nextinfra.info/wp' : 'https://sugotoku-zutool.pocke.tv/wp',
	MOPO_API_URL: isStage ? 'https://stage-sugotoku-zutool.nextinfra.info/mopo' : 'https://sugotoku-zutool.pocke.tv/mopo',
	SUGOTOKU_CONTENTS_ID: isStage ? '02cpn303001' : '01cpn303001',
	SUGOTOKU_HEADER_HEIGHT: 55,
	SUGOTOKU_FOOTER_HEIGHT: 86,
	SEPARATOR: '_po2_zUck1_3tOE.oL8_',
	S3_RESOURCE_URL: 'https://zutool-resource.s3-ap-northeast-1.amazonaws.com'
})
