import * as types from './mutation-types'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'

const uri = `${cmnConst.API_URL}/api/v2/`

export default {
	namespaced: true,

	state: {
		weatherData: null
	},

	mutations: {
		[types.SET_WEATHER_DATA] (state, weatherData) {
			state.weatherData = weatherData
		}
	},

	actions: {
		// 全国マップ（ネイティブ版）と詳細地方用の天気データを取得するAPI
		[types.SET_WEATHER_DATA] ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios.request({
					method: 'get',
					url: `${uri}getmapweathers`,
					params: {
						csid: payload.csid,
						user_id: payload.user_id,
						user_token: payload.user_token,
						format: payload.format
					}
				}).then(res => {
					// NOTE: エラーレスポンス外でもエラーになる場合があるので、statusがNGの場合はrejectするようにした。
					if (res.data.head && res.data.head.status === 'NG') {
						commit(types.SET_WEATHER_DATA, null)
						reject(res)
					// NOTE: ユーザートークンエラー
					} else if (res.data.result_code === 4001) {
						commit(types.SET_WEATHER_DATA, null)
						reject(res)
					} else {
						const weatherData = res.data
						commit(types.SET_WEATHER_DATA, weatherData)
						resolve(res)
					}
				}).catch(error => {
					commit(types.SET_WEATHER_DATA, null)
					reject(error)
				})
			})
		}
	},

	getters: {
		weatherData (state) {
			return state.weatherData
		}
	}
}
