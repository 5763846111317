import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import weatherGraph from './modules/weatherGraph'
import weatherMap from './modules/weatherMap'
import painNote from './modules/painNote'
import column from './modules/column'
import menstruation from './modules/menstruation'
import weatherForecast from './modules/weatherForecast'

Vue.use(Vuex)

// ログアウトなどで初期化する必要のあるものを以下に定義する
const initialState = {
	common: Object.assign({}, common.state),
	weatherGraph: Object.assign({}, weatherGraph.state)
}

export default new Vuex.Store({
	modules: {
		common,
		weatherGraph,
		weatherMap,
		painNote,
		column,
		menstruation,
		weatherForecast
	},
	mutations: {
		resetState (state) {
			Object.keys(state).forEach(key => {
				Object.assign(state[key], initialState[key])
			})
		},
		resetStateOne (state, serviceName, stateName) {
			state[serviceName][stateName] = null
		}
	},
	actions: {
		resetState ({ commit }) {
			commit('resetState')
		},
		resetStateOne ({ commit }, payload) {
			commit('resetStateOne', payload.serviceName, payload.stateName)
		}
	}
})
